/**
 * Renders a section with two Columns
 * Column: Component with a list of TextBlocks and Images
 * choose alignment of Columns
 */

import PropTypes from 'prop-types';
/** @jsx jsx this comment is required for Storybook to render */
import { css, jsx } from '@emotion/core';
import getOr from 'lodash/fp/getOr';
import DeprecatedGrid from '../../atoms/Grid/DeprecatedGrid';
import Image from '../../atoms/Image/Image';
import TextBlock from '../../molecules/TextBlock/TextBlock';
import InlineVideo from '../../molecules/InlineVideo/InlineVideo';
import TextBlockWithIconRowSection from '../TextBlockWithIconRowSection/TextBlockWithIconRowSection';
import ProductScreenAnimation from '../../molecules/ProductScreenAnimation/ProductScreenAnimation';
import AssetDownload from '../../molecules/AssetDownload/AssetDownload';
import sanityImage from '../../../utils/sanityImage';
import PageWrapper from '../PageWrapper/PageWrapper';
import {
  breakpoints,
  pageDimensions,
  unitConverter as uc,
} from '../../../styles/base';
import Section from '../../molecules/Section/Section';
import TextBlockContent from '../../molecules/TextBlockContent/TextBlockContent';

// render each column
const renderBlocks = (columnBlocks, blockCss) => {
  return columnBlocks.map(block => {
    // get type from block
    const blockType = block._type;

    const textCss = css`
      display: flex;
      flex-direction: column;
      max-width: ${uc('470px')};
      padding: ${uc('0 20px')};
    `;

    const imageCss = css`
      z-index: 1;
      flex: 0 0 auto;

      @media (${breakpoints.tablet}) {
        transform: none;
      }
    `;

    const lottieCss = css`
      @media (${breakpoints.desktop}) {
        .lottie-wrapper {
          position: inherit;
          margin-left: ${uc('20px')};
        }
      }

      @media (${breakpoints.mobile}) {
        .lottie-ref {
          position: inherit;
          transform: none;
        }

        .secondary-image {
          display: none;
        }

        > * {
          overflow: visible;
        }
      }
    `;

    // render different component depending on block type.
    switch (blockType) {
      case 'productScreenAnimation':
        return (
          <ProductScreenAnimation
            css={[blockCss, lottieCss]}
            key={`product-screen-${block._key}`}
            {...block}
          />
        );
      case 'csodImage':
        return (
          <div
            className="csod-image-wrapper"
            key={`text-block-image-wrapper-${block._key}`}
            css={blockCss}
          >
            <Image
              css={imageCss}
              src={sanityImage(block.asset)
                .auto('format')
                .url()}
              {...block}
            />
          </div>
        );
      case 'inlineVideo': {
        const { thumbnail, videoURL } = block;
        return (
          <InlineVideo
            key={`text-block-inline-video-component-${block._key}`}
            video={videoURL}
            image={thumbnail}
          />
        );
      }
      case 'textBlockWithIconRowSection': {
        return (
          <TextBlockWithIconRowSection
            key={`text-block-icon-row-component-${block._key}`}
            {...block}
          />
        );
      }
      case 'downloadAsset': {
        return (
          <AssetDownload
            key={`asset-download-component-${block._key}`}
            label={block.label}
            previewURL={sanityImage(block.preview)
              .auto('format')
              .maxWidth(400)
              .url()}
            assetURL={getOr('', 'asset.asset.url', block)}
          />
        );
      }
      case 'textBlockContent': {
        return (
          <div
            className="text-block-wrapper"
            key={`text-block-wrapper-${block._key}`}
            css={blockCss}
          >
            <TextBlockContent
              css={textCss}
              key={`text-block-component-${block._key}`}
              {...block}
            />
          </div>
        );
      }
      case 'textBlock':
      default: {
        return (
          <div
            className="text-block-wrapper"
            key={`text-block-wrapper-${block._key}`}
            css={[blockCss]}
          >
            <TextBlock
              css={textCss}
              key={`text-block-component-${block._key}`}
              {...block}
            />
          </div>
        );
      }
    }
  });
};

const TwoColumnSection = ({
  backgroundImage,
  leftBlock,
  rightBlock,
  sectionStyles,
  ...props
}) => {
  const twoColumnSectionCss = css`
    position: relative;
    display: flex;
    min-height: ${uc('700px')};
    background-image: url(${sanityImage(backgroundImage)
      .auto('format')
      .width(pageDimensions.rawLargeDesktopWidth)
      .fit('max')
      .url()});
    background-repeat: no-repeat;
    background-position: center top;
    background-size: ${pageDimensions.largeDesktopWidth};

    @media (${breakpoints.mobile}) {
      min-height: auto;
    }
  `;

  const gridCss = css`
    flex-wrap: nowrap;

    > div {
      flex: 1 0 50%;
      margin: ${uc('auto')};
    }

    @media (${breakpoints.tablet}) {
      flex-wrap: wrap;

      > div {
        flex: 0 auto;
        width: 100%;
        margin: ${uc('20px')};
        overflow: visible;
        text-align: center;
      }
    }
  `;

  // align left block based on align or columnAlign value
  let leftBlockAlignCss;

  if (leftBlock && leftBlock.align) {
    leftBlockAlignCss = css`
      > * {
        position: absolute;
        top: ${uc('100px')};
        left: ${uc('-20px')};

        @media (max-width: ${uc('1768px')}) {
          max-width: ${uc('960px')};
        }

        @media (${breakpoints.desktop}) {
          top: 25%;
          max-width: 63%;
        }

        @media (${breakpoints.largeTablet}) {
          max-width: 50%;
        }

        @media (${breakpoints.tablet}) {
          position: unset;
          max-width: 100%;
        }
      }
    `;
  } else if (leftBlock && leftBlock.columnAlign === 'left') {
    leftBlockAlignCss = css`
      > div {
        margin-left: 0;

        @media (${breakpoints.tablet}) {
          margin: ${uc('15px auto')};
        }
      }
    `;
  } else if (leftBlock && leftBlock.columnAlign === 'right') {
    leftBlockAlignCss = css`
      > div {
        margin-right: 0;

        @media (${breakpoints.tablet}) {
          margin: ${uc('15px auto')};
        }
      }
    `;
  }

  // align right block based on align or columnAlign value
  let rightBlockAlignCss;

  if (rightBlock && rightBlock.align) {
    rightBlockAlignCss = css`
      > * {
        position: absolute;
        top: ${uc('100px')};
        right: ${uc('-20px')};

        @media (max-width: ${uc('1768px')}) {
          max-width: ${uc('960px')};
        }

        @media (${breakpoints.desktop}) {
          top: 25%;
          max-width: 63%;
        }

        @media (${breakpoints.largeTablet}) {
          max-width: 50%;
        }

        @media (${breakpoints.tablet}) {
          position: unset;
          max-width: 100%;
        }
      }
    `;
  } else if (rightBlock && rightBlock.columnAlign === 'left') {
    rightBlockAlignCss = css`
      > div {
        margin-right: auto;
        margin-left: ${uc('20px')};

        @media (${breakpoints.tablet}) {
          margin: ${uc('15px auto')};
        }
      }
    `;
  } else if (rightBlock && rightBlock.columnAlign === 'right') {
    rightBlockAlignCss = css`
      > div {
        margin-right: ${uc('20px')};
        margin-left: auto;

        @media (${breakpoints.tablet}) {
          margin: ${uc('15px auto')};
        }
      }
    `;
  }

  return (
    <Section sectionStyles={sectionStyles} css={twoColumnSectionCss} {...props}>
      <PageWrapper className="page-wrapper">
        <DeprecatedGrid
          css={gridCss}
          flexDirection="row"
          justifyContent="space-between"
        >
          {leftBlock && renderBlocks(leftBlock.columnBlocks, leftBlockAlignCss)}
          {rightBlock &&
            renderBlocks(rightBlock.columnBlocks, rightBlockAlignCss)}
        </DeprecatedGrid>
      </PageWrapper>
    </Section>
  );
};

/**
 * Prop types
 */
TwoColumnSection.propTypes = {
  backgroundImage: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  leftBlock: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.arrayOf(PropTypes.shape({})),
  ]),
  rightBlock: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.arrayOf(PropTypes.shape({})),
  ]),
  sectionStyles: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape({})),
    PropTypes.shape({}),
  ]),
};

TwoColumnSection.defaultProps = {
  backgroundImage: '',
  leftBlock: null,
  rightBlock: null,
  sectionStyles: {},
};

export default TwoColumnSection;
